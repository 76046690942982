
.circle-wrapper {
  position: relative;
}

.circled-text {
  background-color: white;
  color: $navy;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}


.services-text {
  font-family: wotfardlight;
  line-height: 1.5;
  letter-spacing: 0.45em;
  @extend .pt23;
}

.services-text.bold {
  font-family: wotfardmedium;

}

.circle-wrapper {
  position: relative;
  top: 0;
  //width: 400px;
  //height: 400px;
}

.sass-services-col {
  //  width: 1024px;
  //max-width: 86%;
  display: flex;
  justify-content: space-between;
  //  padding-top: 100px;

  @include media-breakpoint-up(xxl) {
    //width: 1280px;
  }

  &:first-of-type {
    margin-top: 20px;
  }

  &:last-of-type {
    margin-bottom: 100px;
  }

}


$circle-size-1: 500px;
$circle-size-2: 240px;
$circle-size-2: 300px;
$circle-size-3: 450px;


#circle-1 {
  .circled-text {
    width: $circle-size-1;
    height: $circle-size-1;
  }

  left: -100px;
  top: 0px;
}

#circle-2 {
  .circled-text {
    width: $circle-size-1;
    height: $circle-size-1;
  }

  right: 224px;
  right: 164px;
  top: 10px;
}

#circle-3 {
  .circled-text {
    width: $circle-size-2;
    height: $circle-size-2;
  }

  top: -100px;
  left: -79px;
}


#circle-4 {
  .circled-text {
    width: $circle-size-3;
    height: $circle-size-3;
  }

  left: 45px;
  top: 76px;
}

#circle-5 {
  .circled-text {
    width: $circle-size-3;
    height: $circle-size-3;
  }

  right: 2px;
  top: 86px;
}

@include media-breakpoint-down(bfl) {

  #circle-1 {
    left: -139px;
    left: -169px;
    top: 0px;
  }

  #circle-2 {
    //width: $circle-size-1;
    //height: $circle-size-1;
    right: -56px;
    //    top: 10px;
  }

  #circle-3 {
    //width: $circle-size-2;
    //height: $circle-size-2;
    //left: -67px;
    //top: -56px;
    //left: -4px;
    //top: -59px;
    left: -62px;
    left: -84px;
    //    top: -73px;
  }


  #circle-4 {
    //width: $circle-size-3;
    //height: $circle-size-3;
    //top: 78px;
    //left: -43px;

    left: -63px; //77px;
    //    top: 63px;
  }

  #circle-5 {
    //width: $circle-size-3;
    //height: $circle-size-3;
    right: 22px;
    right: 7px;
    //    top: 50px;
    //top: 56px;
  }

}

$circle-xl-scaler: 0.8;
@include media-breakpoint-down(xxl) {

  #circle-1 {
    .circled-text {
      width: $circle-size-1 * $circle-xl-scaler;
      height: $circle-size-1 * $circle-xl-scaler;
    }

    left: 21px;
  }

  #circle-2 {
    .circled-text {
      width: $circle-size-1 * $circle-xl-scaler;
      height: $circle-size-1 * $circle-xl-scaler;
    }

    right: 87px;
  }

  #circle-3 {
    .circled-text {
      width: $circle-size-2 * $circle-xl-scaler;
      height: $circle-size-2 * $circle-xl-scaler;
    }

    left: -11px;
    top: -55px;
  }


  #circle-4 {
    .circled-text {
      width: $circle-size-3 * $circle-xl-scaler;
      height: $circle-size-3 * $circle-xl-scaler;
    }

    left: 47px;
    top: 56px;
  }

  #circle-5 {
    .circled-text {
      width: $circle-size-3 * $circle-xl-scaler;
      height: $circle-size-3 * $circle-xl-scaler;
    }

    right: 67px;
    top: 70px;
  }

}

$circle-lg-scaler: 0.65;
@include media-breakpoint-down(xl) {

  //.sass-services-col {
  //  width:1000px;
  //}
  .circled-text {
    //    font-size:0.9em;
  }
  .services-text {
    font-size: 0.95em;
  }

  #circle-1 {
    .circled-text {
      width: $circle-size-1 * $circle-lg-scaler;
      height: $circle-size-1  * $circle-lg-scaler;
    }

    left: 5px;
    //top: -25px;
  }

  #circle-2 {
    .circled-text {
      width: $circle-size-1 * $circle-lg-scaler;
      height: $circle-size-1  * $circle-lg-scaler;
    }

    right: 65px;
    //top: 0px;
  }

  #circle-3 {
    .circled-text {
      width: $circle-size-2 * $circle-lg-scaler;
      height: $circle-size-2 * $circle-lg-scaler;
    }

    left: -10px;
    top: -65px;
  }


  #circle-4 {
    .circled-text {
      width: $circle-size-3 * $circle-lg-scaler;
      height: $circle-size-3 * $circle-lg-scaler;
    }

    left: 58px;
    top: 45px;
  }

  #circle-5 {
    .circled-text {
      width: $circle-size-3 * $circle-lg-scaler;
      height: $circle-size-3 * $circle-lg-scaler;
    }

    right: 40px;
    top: 10px;
    right: 0px;
    top: 50px;
  }

}

@include media-breakpoint-down(lg) {

  .sass-services-col {
    flex-wrap: wrap;
    padding-top: 0;
  }

  .sass-services-col > * {
    display: block;
    flex-wrap: wrap;
  }

  $circle-size-m: 425px;

  .services-text {
    font-size: 1.2em;
  }

  .circle-wrapper {
    position: relative;
    margin: 0 auto;
    width: $circle-size-m;
    height: $circle-size-m;
    margin-top: 1em;
    margin-bottom: 1em;

    display: flex;
    justify-content: center;
    height: auto;

    &:first-of-type {
      // margin-top:0;
    }

  }

  .circled-text {
    width: $circle-size-m !important;
    height: $circle-size-m !important;
  }

  #circle-1 {
    left: 0px;
    top: 0px;
  }

  #circle-2 {
    left: 0px;
    top: 0px;
  }

  #circle-3 {
    left: 0px;
    top: 0px;
  }


  #circle-4 {
    left: 0px;
    top: 0px;
  }

  #circle-5 {
    left: 0px;
    top: 0px;
  }

}

@include media-breakpoint-down(sm) {

  $circle-size-sm: 280px;

  .circled-text {
    width: $circle-size-sm !important;
    height: $circle-size-sm !important;
    font-size: 0.85em;
  }


  .services-text {
    //    font-size: 0.95em;
    font-size: 1em;
  }

  .circle-wrapper {
    position: relative;
    margin: 0 auto;
    width: $circle-size-sm;
    height: $circle-size-sm;
    margin-top: 1em;
    margin-bottom: 1em;

    &:first-of-type {
      // margin-top:0;
    }

  }

  #circle-1 {
    left: 0px;
    top: 0px;
    width: $circle-size-sm;
    height: $circle-size-sm;
  }

  #circle-2 {
    left: 0px;
    top: 0px;
    width: $circle-size-sm;
    height: $circle-size-sm;
  }

  #circle-3 {
    left: 0px;
    top: 0px;
    width: $circle-size-sm;
    height: $circle-size-sm;
  }


  #circle-4 {
    left: 0px;
    top: 0px;
    width: $circle-size-sm;
    height: $circle-size-sm;
  }

  #circle-5 {
    left: 0px;
    top: 0px;
    width: $circle-size-sm;
    height: $circle-size-sm;
  }

}



