
.footer {
  background-color: $navy;
}


.footer-container {
  padding-bottom: 40px;
}

.siteby {
  $siteby-color: #8285e1;
  font-size:0.9em;
  color:$siteby-color;
  background-color: $navy;
  height:40px;
  display:flex;
  justify-content: flex-start;
  padding:0 1em;
  a {
    color:$siteby-color;
    text-decoration: none;
  }
}


.footer-side {
  flex: 1 1 366px;

  flex: 0 1 310px;

  justify-self: flex-end;
  display: flex;
  justify-content: flex-end;

  &.left-side {
    flex: 0 5 408px;
  }

  &.right-side {
    flex: 0 5 550px;
    padding-right: 25px;
    justify-content: flex-end;

  }
}


.footer-centre {

  flex: 0 1 $sass-center-width;
  //min-width:840px;
  //  max-width: $sass-center-width;
  padding-top: 50px;
  justify-content: flex-start;
}


.footer-contact {
  letter-spacing: 0.25em;
  letter-spacing: 0.035em;
  line-height: 1.3;
}


@include media-breakpoint-down(xl) {
  .footer-centre {
    //flex: 1 0 auto;
    //max-width: 820px;
  }
  .footer-contact-spacer {

  }
  .footer-contact-left {
    //  flex: 1 1 40px;
  }
  .footer-contact-spacer {
    flex: 1 0 30px;
  }
}

@include media-breakpoint-down(md) {
  .footer-centre {
    flex: 1 1 auto;
  }
}

.footer-contacts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 30px 0;
  font-weight: $fo-light;

  flex: 1 0 710px;

  @include media-breakpoint-up(md) {
    min-width: 740px;
  }

  //max-width:900px;justify-content: space-between
}

.footer-contact-left {
  flex: 0 2 140px;
  //max-width: 136px;
}

.footer-contact-spacer {
  flex: 0 0 40px;
  flex: 0 0 100px;
  flex: 1 0 60px;
  max-width: 100px;

  //min-width: 40px;
}


.left-contact {
  flex: 1 0 450px;
  flex: 1 0 370px;
  flex: 0 0 385px;
}

.right-contact {
  flex: 1 0 300px;
}

.footer-wrapper {
  display: flex;
  //flex: 0 0 1684px;
  margin-top: 50px;
}

@include media-breakpoint-down(lg) {
  .footer-wrapper {
    flex-wrap: wrap;
    padding: 30px;
  }
  .footer-centre {
    padding-top: 0px;
  }

  .footer-side {
    justify-self: center;
    justify-content: flex-start;

    &.left-side {

    }

    &.right-side {
      flex: 1 1 auto;

    }
  }
  .footer-contact-spacer {
    display: none;
  }
  .footer-contacts {
    flex-wrap: wrap;
  }
  .footer-contact {
    flex: 0 0 100%;
    margin-bottom: 40px;
  }
}


@include media-breakpoint-down(md) {
  .footer-contact-left {
    display: none;
  }
  .footer-centre {
    //font-size: 1em;
  }


}

@include media-breakpoint-down(sm) {

  .footer-centre {
    font-size: 0.9em;
  }

}

@include media-breakpoint-down(sm) {

  .footer-centre {
    //font-size:0.7em;
  }

}

#footer-address-br {
  display: none;
  @media (max-width: 420.98px) {
    display: inline;
  }
}


