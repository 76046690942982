@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "fonts";
@import "debug";


$fo-medium: wotfardlight;
$fo-light: wotfardextralight;

.watmedium {
  font-family: $fo-medium;
}

.fo-regular {
  font-family: $fo-medium;
}

.fo-light {
  font-family: $fo-light;
}

.bg-navy {
  background-color: $navy;
}

.navy {
  color: $navy;
}

.grey {
  color: $grey;
}

.white {
  color: $white;
}

html, body {
  background: #fff;
  font-family: wotfardextralight;
  letter-spacing: 0.02em;
  color: $navy;
  //  font-size: 23pt * $pt-mult;

  //font-size: 18pt;
  font-size: 16pt;

  @include media-breakpoint-down(sm) {
    //font-size: 23pt * $pt-mult * 0.85;
  }
}

P {
  line-height: 1.43 !important;
}

// Layout
.sass-container {
  @extend .container-fluid;
}

.sass-row {
  @extend .row;
  @extend .justify-content-center;
}

.sass-8 {
  flex: 0 0 auto;
  width: 100%;
}

@import 'footer';


.sass-center-width {
  flex: 0 1 960px;
  max-width: 960px;

  flex: 0 1 1280px;
  max-width: 1280px;
}


.menu-container {
  @extend .container-fluid;
  background-color: $navy;
  @include media-breakpoint-down(lg) {
    background-color: white;
    align-items: center;
  }
}

#mobile-logo {
  background-image: url('../assets/Sassafras mobile.png');
  background-repeat: no-repeat;

  width: 310px;
  height: 110px;
  background-size: contain;
  //  display: bloxk;
  background-position: center center;

  @include media-breakpoint-down(md) {
    margin-left: 20px;
  }
  @include media-breakpoint-down(sm) {
    margin-left: 0px;
    width: 200px;
    max-width: 75%;
    height: 70px;
  }

}


.sass-nav-wrapper {
  font-family: wotfardlight;
  height: $height_header;
  //background-color: $navy;
  height: $height_header;
  display: flex;
  justify-content: space-around;
  align-items: center;

  color: $white;
  @include media-breakpoint-down(lg) {

    color: $navy;


  }
}

.sass-nav {
  @extend .nav;

  padding-left: 20px;

  li {
    a {

      padding: 10px 50px;


      font-family: wotfardmedium;
      font-family: wotfardlight;

      font-size: 20pt  * $pt-mult;
      font-size: 15.7pt;
      //      @extend .pt20;

      letter-spacing: $menu-letter-spacing;

      &:hover {
        color: $grey;
      }

      color: white;
      @include media-breakpoint-down(lg) {
        color: $navy;

      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .sass-nav-wrapper {
    //height: auto;

    .nav-item {
      //flex: 0 0 100%;

      a {
        padding: 10px 22px;
      }
    }

  }
}

@include media-breakpoint-down(lg) {

  .navbar-brand {
    margin-left: 20px;
  }
  .sass-nav-wrapper {
    height: auto;

    ul.nav-pills {
      padding-left: 0;
      padding-bottom: 1em;
    }

    .nav-item {
      flex: 0 0 100%;

      a {
        padding: 10px 20px;
      }
    }

  }
}


.navy-underline {
  border-bottom: 1px solid $grey;

}

@import 'slides';

.carousel-control-prev, .carousel-control-next {
  text-align: center;
  opacity: 0.5;
  border: none;
  background-color: transparent;
  transition: opacity 0.15s ease;
}

body#what-people-say {

  .carousel-control-prev, .carousel-control-next {
    align-items: flex-start;
    top: 120px;
    width: 60px;


  }


  .carousel-control-next {

  }
}


@include media-breakpoint-down(lg) {

  body#what-people-say {

    .carousel-control-prev, .carousel-control-next {
      display: inline-block;
      position: relative;
      margin-top: 30px;
      @include media-breakpoint-down(lg) {
        margin-top: 0;
        top: 60px;
        margin-top: 0px;
      }

    }

    .carousel-control-next {
      right: 0;
      float: right;
    }
  }
}


.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
}

.banner-image {
  background-repeat: no-repeat;
  background-size: 100vw;
  background-size: cover;
  background-position-x: center;
  background-position-y: top;

  height: 39.4vw;
  min-height: 380px;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  @include media-breakpoint-down(md) {
    background-size: cover;
  }
  @include media-breakpoint-down(sm) {
    //min-height: 360px;
  }
}


#banner-1 {
  background-image: url('../assets/Home Page Banner.jpg');
}

#banner-2 {
  background-image: url('../assets/About Page Banner.jpg');
}

#banner-3 {
  background-image: url('../assets/Services Page Banner.jpg');
}

#banner-4 {
  background-image: url('../assets/Experience Page Banner.jpg');
}

#banner-5 {
  background-image: url('../assets/What People Say banner.jpg');
}


.main-logo {
  position: absolute;
  margin-top: 2.2341vw;

  img {
    height: 7.447vw;
  }

  @include media-breakpoint-down(lg) {
    display: none;
  }

}

#home-logo {
  position: absolute;
  margin-top: 2.2341vw;
  height: 7.447vw;
}


// page title sections

.page-title-section {
  background-color: $navy;
  height: auto;

  #service-page & {
    height: auto;
  }

  //padding-top: 114px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .page-title-block {
    flex: 0 0 90%;

    margin-top: 110px;
    margin-bottom: 160px;

    display: flex;
    justify-content: center;
    align-items: center;

    .page-title {
      color: $white;
      padding: 0 35px;

      //@extend .pt35;
      font-size: 27pt;
      font-size: 25pt;


      @include media-breakpoint-down(md) {
        padding: 0 5px;
        font-size: 16pt;
      }
      @include media-breakpoint-down(sm) {
        padding: 0 5px;
        font-size: 16pt;
      }
      font-family: wotfardmedium;
      letter-spacing: 0.25em;;
      text-align: center;
    }

  }

  div.page-line {
    flex: 1 0 auto;
    height: 2px;
    border-bottom: 2px solid $grey;

  }


}

.br-for-small {
  display: none;
}

@media (max-width: 360px) {
  #what-people-say {
    .page-title {
      font-size: 0.8em;

    }
  }
}

//#about-title {
//  height: 344px;
//}
//
//#wps-title {
//  height: 344px;
//}
//
//#experience-title {
//  height: 344px;
//}

@include media-breakpoint-down(lg) {
  .page-title-section {
    //padding-top: 64px;
    //padding-bottom: 64px;
    height: auto;

    .page-title-block {
      flex: 0 0 90%;
      margin-top: 64px;
      margin-bottom: 64px;
    }
  }

  //#about-title {
  //  height: 264px;
  //}
  //#wps-title {
  //  height: 264px;
  //}
  //#experience-title {
  //  height: 264px;
  //}

}


$size-of-sarah: 480px;

.about-sarah-row {

}

.about-sarah-wrapper {
  position: relative;
}

.about-sarah-inset {
  position: absolute;
  bottom: 0;
}


div#about-sara-abbott,
div.about-sara-abbott {
  height: $size-of-sarah;
  width: $size-of-sarah;
  position: absolute;
  left: ($size-of-sarah / -2);
  top: (-$size-of-sarah);
  z-index: 2;

  img {
    width: 100%;
    border-radius: 50%;
  }
}

.about-sarah-title {
  //background-color: lightcyan;

  font-size: 23pt;
  line-height: $line-height;
  //  font-size: 1.4em;
  padding-bottom: 1.4em;

  margin-top: 220px;
  padding-left: 290px;

  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }

}


@include media-breakpoint-down(xxl) {
  div#about-sara-abbott,
  div.about-sara-abbott {
    left: -30px;
  }
  #sabout-sarah-title,
  .about-sarah-title {
    padding-left: 510px;
    margin-top: 167px;
  }
}


.sarah-abbot-br {
  display: none;
}

.about-sarah-content {
  margin-top: 1em;

  @include media-breakpoint-down(sm) {
    p {
      font-size: $body-font-size-xs !important;
      line-height: $line-height !important;
    }
  }
}

@include media-breakpoint-down(lg) {

  .about-sarah-inset {
    position: relative;;
  }
  .about-sarah-wrapper {
    display: flex;
    justify-content: center;
    height: auto;
    flex-wrap: wrap;
    margin-top: 40px;
  }
  div#about-sara-abbott {
    left: 0;
    top: 0;
    position: relative;
  }
  .about-sarah-title {
    flex: 0 0 250px;
    flex: 0 0 auto;
    padding-left: 40px;
    margin-top: 40px;
    padding-bottom: 0;

    @include media-breakpoint-down(sm) {
      font-size: $heading-font-size-xs;
      padding-left: 20px;
    }

  }

  .sarah-abbot-br {
    display: inline;
  }

  #about-sarah-content {
    margin-top: 2em;
    margin-bottom: 3em;
  }
}

div#wps-image {
  height: $size-of-sarah;
  width: $size-of-sarah;
  position: absolute;
  left: 20px;
  top: -438px; // (-$size-of-sarah);
  z-index: 2;

  img {
    width: 100%;
    border-radius: 50%;
  }
}

.wps-inset {
  position: absolute;
  bottom: 0;
}

.wps-inset-wrapper {
  position: relative;
}


@include media-breakpoint-down(lg) {
  .wps-inset {
    position: relative;
    margin-top: 60px;
  }
  div#wps-image {
    position: relative;
    top: initial;
    left: initial;
  }
  .wps-inset-wrapper {
    display: flex;
    justify-content: center;
    height: auto;
  }
}


.wps-col {
  flex: 0 0 1280px;
  //  flex: 0 0 50%;
  @include media-breakpoint-down(lg) {
    flex: 0 0 100%;
  }
}


.letter-space-1 {
  //  letter-spacing: 0.34em;
  letter-spacing: 0.2em;
}

.letter-space-2 {
  letter-spacing: 0.45em;
}


$logo-size: 220px;

#footer-logo {
  width: $logo-size;
  height: $logo-size;
  background-image: url('../assets/Sassafras Logo.png');
  background-size: cover;

  flex: 0 0 $logo-size;
  margin-left: 20px;

  @include media-breakpoint-down(lg) {
    margin-left: 0px;
  }
  @include media-breakpoint-down(lg) {
    width: 250px;
    height: 250px;
    flex: 0 0 250px;
  }

}

@include media-breakpoint-down(lg) {
  .footer-side.right-side {
    justify-content: center;;

  }
}


.about-center-col {
  flex: 0 1 920px;

}


@import '_services.scss';


.exp-center-col {
  flex: 0 1 1024px;
  max-width: 1029px;
  flex-wrap: wrap;
}

.exp-container {

  display: flex;
  flex: 0 0 1194px;
  flex: 0 0 1460px;
  flex-wrap: wrap;
  justify-content: space-between;

  @include media-breakpoint-up(lg) {
    padding-top: 100px;
  }


  //display:flex;
  //flex:0 0 50%;
  // flex-wrap: wrap;
}

.exp-col {
  flex: 0 0 calc(50% - 60px);
  @include media-breakpoint-down(lg) {
    flex: 0 0 100%;
  }
}

$exp-col-gap: 5%;

.exp-col-left {
  padding-right: 5%;
}

.exp-col-right {
  padding-left: 5%;
}

@include media-breakpoint-down(xxl) {
  .exp-col-left {
    padding-right: 2%;
  }
  .exp-col-right {
    padding-left: 2%;
  }
}

@include media-breakpoint-down(lg) {
  .exp-col-left {
    padding-right: 0;
  }
  .exp-col-right {
    padding-left: 0;
  }
}

.exp-wrapper {
  flex: 0 0 100%;

  //padding: 20px 30px;

  .exp {
    padding: 20px 26px;
    font-family: $fo-light;

    .exp-title {
      //      font-size: 30pt * $pt-mult;

      font-size: 22pt;
      @include media-breakpoint-down(sm) {
        font-size: $heading-font-size-xs; //23pt * $pt-mult;
      }

      @extend .letter-space-2;
      margin-bottom: 0.5em;
      margin-top: 1.2em;
      line-height: $line-height;

      .bolder {
        font-family: $fo-medium;
        font-family: wotfardmedium;
      }
    }

    .exp-body {
      line-height: $line-height;
    }

  }

}

@include media-breakpoint-down(lg) {
  .exp-wrapper {
    .exp {
      padding: 15px 15px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .exp-title {
    font-size: 22pt !important;
  }
}

@include media-breakpoint-down(sm) {
  .exp {
    padding: 15px 0 !important;
  }
  .exp-title {
    font-size: $heading-font-size-xs !important;;
  }
  .exp-body {
    font-size: $body-font-size-xs !important;
    line-height: $line-height !important;
  }

}


$exp-inset-t-offset: 150px;
$exp-inset-size: 650px;

.exp-inset-wrapper {
  position: relative;
  bottom: 0;
  height: $exp-inset-size - $exp-inset-t-offset + 40px;
}

.exp-image-filler {
  display: flex;
  justify-content: center;

  img {
    display: none;
  }

  height: 540px;
  height: 490px;

  @include media-breakpoint-down(lg) {
    height: 310px;
    img {
      display: block;
    }
    //    height: 0;
  }
}


div.exp-inset {
  height: $exp-inset-size;
  width: $exp-inset-size;
  position: absolute;
  //left: 150px;
  right: -168px;
  top: -$exp-inset-t-offset;
  top: -88px;
  z-index: 2;


  img {

    width: 100%;
    border-radius: 50%;
  }
}

@include media-breakpoint-down(xxl) {

  div.exp-inset {

    height: 520px;
    width: 520px;

    right: 0px;
  }

  .exp-image-filler {
    height: 380px;
  }

}

@include media-breakpoint-down(xl) {

  div.exp-inset {

    height: 450px;
    width: 450px;

    right: 0px;
  }

  .exp-image-filler {
    height: 310px;
  }

}

@include media-breakpoint-down(lg) {

  .exp-image-filler {
    height: 450px;
    max-height: 70vw;
  }

  .exp-inset-wrapper {
    display: flex;
    justify-content: center;
    height: auto;

  }
  div.exp-inset {
    height: $exp-inset-size;
    left: 0;
    top: 0;
    position: relative;
  }
}

.wps-slide {
  //  background-color: lightgoldenrodyellow;
  flex: 0 1 780px;
  flex: 0 1 730px;
  flex: 0 1 820px;
  //  max-width:700px;
}

#what-people-say #testimonials_carousel {
  margin-top: 110px;
  margin-bottom: 120px;
  max-width: 1280px;

  @include media-breakpoint-down(lg) {
    margin-top: 60px;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 40px;
  }

  .carousel-item {
    //display:flex;
    //justify-content: center;;
  }
}

.wps-quote {
  padding-bottom: 30px;
  @include media-breakpoint-up(lg) {
    //  min-height: 360px;
    padding-bottom: 0px;
  }
  margin-bottom: 40px;

  line-height: $line-height;

  @include media-breakpoint-down(sm) {
    font-size: $body-font-size-xs;
  }
}

.wps-author {
  font-family: wotfardmedium;
  @extend .letter-space-2;

  @include media-breakpoint-down(sm) {
    font-size: $heading-font-size-xs;
  }
}

.wps-author-and {
  font-family: wotfardextralight;
}

.wps-author-info {

}

.wps-wrapper {
  display: flex;
  justify-content: center;
}


.sass-intro-wrapper {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  //height: 344px;

  padding-top: 70px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 90px;

}

.sass-intro-block {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.sass-intro-logo {
  flex: 0 0 196px;
  flex: 0 0 220px;

  img {
    transform: rotate(90deg);
  }
}

.sass-intro-filler {
  flex: 1 0 68px;
}

.sass-intro-text {
  flex: auto;
  //padding-top:30px;

  p:last-child {
    margin-bottom: 0;
  }

}

@include media-breakpoint-down(lg) {

  .sass-intro-wrapper {
    height: auto;
    padding-top: 40px;
  }

  .sass-intro-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

  }

  .sass-intro-logo {
    flex: 1 0 100%;
    max-width: 480px;
    max-width: 600px;
    max-width: 425px;
    margin: 20px;
  }

  .sass-intro-filler {
    display: none;
  }

  .sass-intro-text {
    margin-top: 40px;
    flex: 0 0 100%;
  }
}

@include media-breakpoint-down(lg) {


}

.navbar-toggler {
  border: 0;
  padding: 0.25rem;
  padding-top: 0;
  padding-bottom: 20px;

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
    padding-bottom: 13px;
  }
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

