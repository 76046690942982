@font-face {
  font-family: 'wotfardbold';
  src: url('../fonts/wotfard-roman/wotfard-bold-webfont.eot');
  src: url('../fonts/wotfard-roman/wotfard-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/wotfard-roman/wotfard-bold-webfont.woff2') format('woff2'),
  url('../fonts/wotfard-roman/wotfard-bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'wotfardlight';
  src: url('../fonts/wotfard-roman/wotfard-light-webfont.eot');
  src: url('../fonts/wotfard-roman/wotfard-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/wotfard-roman/wotfard-light-webfont.woff2') format('woff2'),
  url('../fonts/wotfard-roman/wotfard-light-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'wotfardmedium';
  src: url('../fonts/wotfard-roman/wotfard-medium-webfont.eot');
  src: url('../fonts/wotfard-roman/wotfard-medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/wotfard-roman/wotfard-medium-webfont.woff2') format('woff2'),
  url('../fonts/wotfard-roman/wotfard-medium-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'wotfardextralight';
  src: url('../fonts/wotfard-roman/wotfard-extralight-webfont.eot');
  src: url('../fonts/wotfard-roman/wotfard-extralight-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/wotfard-roman/wotfard-extralight-webfont.woff2') format('woff2'),
  url('../fonts/wotfard-roman/wotfard-extralight-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


//@import url('../fonts/wotfard-roman/stylesheet.css');
//@import url('../fonts/wotfard-italic/stylesheet.css');

