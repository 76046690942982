
.bg-red {
  background-color: rgba(255, 0, 0, 0.22);
}

.bg-green {
  background-color: rgba(0, 128, 0, 0.34);
}

.bg-blue {
  background-color: rgba(0, 0, 255, 0.3);
}

.show-width-panel {
  position: relative;
  width: 0;
  height: 0px;
  top: -17px;
  align-self: flex-start;
  z-index: 9999;
}


.swp-wrapper {
  position: relative;
  width: 100%;
  height: 0px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  text-shadow: 4px 4px 2px black;

  border-top: 1px solid lightgoldenrodyellow;

  .swp-l {
    width: 22px;
    height: 22px;

    &::before {
      color: lightgoldenrodyellow;
      content: '<';
      font-size: 22px;
      line-height: 1em;
      width: 22px;
      height: 22px;
      position: relative;
      top: -5px;
      left: 0;
    }
  }

  .swp-m {
    position: relative;
    //top:-14px;
    font-size: 14px;
    text-align: center;
    background-color: lightgoldenrodyellow;
    color: black;
    padding: 4px 20px;
    line-height: 1em;
    text-shadow: none;
  }

  .swp-r {
    width: 22px;
    height: 22px;
    text-align: right;

    &::before {
      color: lightgoldenrodyellow;
      content: '>';
      font-size: 22px;
      line-height: 1em;
      width: 22px;
      height: 22px;
      position: relative;
      top: -5px;

      right: 0;
    }

  }
}

.show-width {
  .show-width-panel {
    display: none;
  }

  &:hover,
  &.show-width-active {
    .show-width-panel {
      display: block;

    }
  }
}


.indicator {

  background-color: rgba(230, 255, 0, 0.66);
  border-bottom-color: rgba(62, 62, 61, 0.87);

  /*top: 0;*/
  /*left: 50%;*/
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  padding: 1px !important;
  text-align: center;

  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    background-color: rgba(132, 255, 0, 0.66);
  }
}

.indicator-panel {
  sposition: fixed;
  z-index: 10000;
  height: 0;
  width: 100%;
  cursor: pointer;
  padding-left: 0px;
  padding-right: 0px;
}

#indicator-container.indicator-bottom {
  bottom: 16px;

}
