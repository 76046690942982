/* colours */

$navy: #282a66;
$grey: #bcbec0;
$white: white;

$line-height: 1.45;

$sass-center-width: 1280px;
$sass-center-width: 1100px;


/* dimensions  */
//$height_header : 142px;
$height_header: 112px;

$heading-font-size-xs: 0.87rem;
$body-font-size-xs: 0.85rem;


$menu-letter-spacing: 0.3em;
$menu-font-size: 0.92em;

$pt-mult: (21pt / 26.7pt);

.pt20 {
  font-size: 20pt  * $pt-mult;
}

.pt21 {
  font-size: 21pt  * $pt-mult;
}

.pt23 {
  font-size: 23pt  * $pt-mult;
}

.pt35 {
  font-size: 35pt * $pt-mult;
}

.pt30 {
  font-size: 30pt * $pt-mult;
}

/* Device = Most of the Smartphones Mobiles (Portrait) */
//$screen-xxs-min: 320px;
//$screen-xxs-max: 480px;
//
///* Device = Low Resolution Tablets, Mobiles (Landscape) */
//$screen-xs-min: 481px;
//$screen-xs-max: 767px;
//
///* Device = Tablets, Ipads (portrait) */
//$screen-sm-min: 768px;
//$screen-sm-max: 1024px;
//
///* Device = Laptops, Desktops */
//$screen-md-min: 1025px;
//$screen-md-max: 1280px;
//
///* Device = Desktops */
//$screen-lg-min: 1281px;
//$screen-lg-max: 1440px;
//
///* Higher Resolution Screens */
//$screen-xlg-min: 1441px;
//$screen-xlg-max: 2560px;
//
//$font-family-playfair: 'Playfair Display', serif;
//
//$font-weight-thin:          100;
//$font-weight-exlight:       200;
//$font-weight-light:         300;
//$font-weight-regular:       400;
//$font-weight-medium:        500;
//$font-weight-semibold:      600;
//$font-weight-bold:          700;
//$font-weight-exbold:        800;
//$font-weight-black:         900;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        bfl: 1850px
) !default;

// 1920px

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px,
        bfl: 1830px
) !default;
