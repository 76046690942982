.sass-slide {
  background-repeat: no-repeat;
  background-size: cover;
  height: 51vw; // 994px;
  background-position: center top;
}

#slide-1 {
  background-image: url('../assets/home-slides/Slideshow Image 1.jpg');
}

#slide-2 {
  background-image: url('../assets/home-slides/Slideshow Image 2.jpg');
}

#slide-3 {
  background-image: url('../assets/home-slides/Slideshow Image 3.jpg');
}

#slide-4 {
  background-image: url('../assets/home-slides/Slideshow Image 4.jpg');
}

#slide-5 {
  background-image: url('../assets/home-slides/Slideshow Image 5.jpg');
}

#slide-6 {
  background-image: url('../assets/home-slides/Slideshow Image 6.jpg');
}

#slide-7 {
  background-image: url('../assets/home-slides/Slideshow Image 7.jpg');
}

#slide-8 {
  background-image: url('../assets/home-slides/Slideshow Image 8.jpg');
}

#slide-9 {
  background-image: url('../assets/home-slides/Slideshow Image 9.jpg');
}

#slide-10 {
  background-image: url('../assets/home-slides/Slideshow Image 10.jpg');
}
